import React from 'react'
import Header from '../components/header'
import Intro from '../components/Intro'
import Tokenomics from '../components/Tokenomics'
import HTB from '../components/HTB'
import Chart from '../components/Chart'
import Roadmap from '../components/Roadmap'
import Footer from '../components/Footer'
import Socials from '../components/Socials'

export default function Home() {
  return (
    <div className="wrap">
        <Header />
        <Intro />
        <Tokenomics />
        <HTB />
        {/* <Chart /> */}
        <Roadmap />
        <Socials />
        <Footer />
    </div>
  )
}
