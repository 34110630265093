import React from 'react'
import { FaTelegram, FaTwitter } from 'react-icons/fa'

export default function Socials() {
    const socialsFunc = (icon, link) => ({ icon, link })
    const socialArr = [
        socialsFunc(<FaTwitter />, "#"),
        socialsFunc(<FaTelegram />, "#"),
    ]
    return (
        <div className="wrap py-16 bg-gray-900 px-4">
            <div className="heading text-center text-3xl font-extrabold">
                <span className='w-50 text-pri'>JOIN OUR COMMUNITY & <br />
                    GET EARLY ACCESS</span>
            </div>
            <div className="flex justify-center mt-5 gap-5">
                {socialArr.map((i, index) => (
                    <a href={i.link} key={index} className='text-pri p-4 bg-white bg-opacity-30 text-3xl'>{i.icon}</a>
                ))}
            </div>
        </div>
    )
}
