import React from 'react'
import { FaCaretUp } from 'react-icons/fa'

export default function Footer() {
    return (
        <div className="wrap bg-gray-950">
            <div className="bg-con-footer">
                <div className="container mx-auto backdrop-blur-lg py-8 px-3">
                    <div className="flex flex-wrap gap-3 justify-between">
                        <div className="text-wrap font-bold text-white flex gap-4">
                            <span className='text-pri'>Bill The Goat</span>
                            <span>Copyright © 2024 Book of Jeets</span>
                        </div>

                        <div className="icon">
                            <FaCaretUp />
                        </div>

                        <div className="menu flex flex-wrap gap-4 text-gray-400">
                            <a href="#">HOME</a>
                            <a href="#">ABOUT</a>
                            <a href="#">TOKENOMICS</a>
                            <a href="#">ROADMAP</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
