import React from 'react'

export default function HTB() {
    const textFunc = (text, subText) => ({ text })

    const textArr = [
        textFunc("Connect Your Wallet"),
        textFunc("Select Your Quantity",),
        textFunc("Confirm the Transaction"),
        textFunc("Receive Your Tokens"),
    ]
    return (
        <div className="wrap bg-pri py-16">
            <div className="container mx-auto px-3">
                <div className="heading leading-10">
                    <span className='text-black font-bold text-lg'>EASY STEPS</span> <br />
                    <span className=' text-3xl lg:text-5xl font-extrabold text-amber-800'>HOW TO BUY</span>
                </div>
                <div className="wrap mt-10">
                    <div className="grid gap-8 lg:grid-cols-4">
                        {textArr.map((i, index) => (
                            <div className="flex gap-4 items-center px-4 lg:justify-center bg-black bg-opacity-40 py-5 rounded-xl shadow-lg" key={index}>
                                <span className='text-4xl lg:text-6xl text-amber-800 font-extrabold'>{index + 1}</span> <span className='lg:text-2xl text-lg font-bold text-gray-200'>{i.text}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}
